import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import { Col, Row, Divider, DatePicker, Select, Space } from 'antd';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

// import datanotfound from '../../assets/img/datanotfound.png'
export default function History() {
  const [users, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [date_time, setDate] = useState('');
  const [market_name, setMarketName] = useState('');
  const [loading1, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState(undefined);
  const [loading2, setLoading2] = useState(false);
  const [dateRange, setDateRange] = useState([]);





  const loaduser = async (dateRange) => {
    // setActiveButton(dateRange);

    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    setLoading(true);


    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/manage_commission.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        // alert(res);
        if (res != undefined) {
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes);
        }

      })
      .finally(() => {
        setLoading(false);
      });
  }



  useEffect(() => {
    loaduser();
  }, [])

  return (
    <>
      <section className="margin-bottom-88" id='history'>
        <div className="container-fluid paddingall-5">
          <div className='historybet marketdetails'>
            <Divider orientation="center">Commission List</Divider>
          </div>
          <div className="table-responsive">

            <div className="table  table-history">
              <Table striped bordered hover className=''>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Commission Amount</th>
                    
                    {/* <th>Points </th>
                    <th>Earned</th> */}
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                {/* {user.is_result_declared} */}
                <tbody className='bgbody'>
                  {users && users.length > 0 ? (

                    users && users.map((user, index) => {
                      const dateTime = new Date(user.created_at);
                      console.warn(dateTime);

                      // Format the date in the desired pattern
                      const formattedDate = `${dateTime.getDate()}-${dateTime.getMonth() + 1}-${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}`;
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{formattedDate}</td>
                          <td>{user.market_id
                          }</td>
                          <td>{user.amount}</td>
                          
                          {/* <td>{user.tr_value}</td>
                        <td>{user.win_value}</td> */}
                          {/* <td>{`${user.delStatus == 1 ? 0 : 'wwww'}`}</td> */}
                          {/* {user.delStatus == 1 ?
                          <td className='text-danger trash d-flex justify-content-center align-items-center ' onClick={() => deleteCombination(user.id)}>
                            <i className="bi bi-trash3"></i>
                          </td>
                         
                          :
                          <td></td>
                        } */}
                          <td></td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan="7">No data available or something went wrong.</td>
                    </tr>
                  )}

                </tbody>
              </Table>
            </div>

          </div>



        </div>



      </section >
      {loading1 && (
        // <>
        // <div className="loader_roomcode">
        //   <img src={loader} alt="loader" />
        // </div>
        // </>
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
      <ToastContainer />
      {loading2 && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}

    </>
  )
}
