import React, { useState, useEffect } from 'react';
import { PoweroffOutlined } from '@ant-design/icons';
import { Button, DatePicker, Space } from 'antd';
import Table from 'react-bootstrap/Table';
import Iframe from 'react-iframe';
// import {View, Image} from 'react-native';
// import {WebView} from 'react-native-webview';
// import { WebView } from 'react-native-webview';

export default function Resulthistory() {
  const user_id = localStorage.getItem("userid");
  const [isLoading, setIsLoading] = useState(true);
  const [iframeContent, setIframeContent] = useState(null);



  useEffect(() => {
      setIsLoading(false);
  }, []);

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/POM_bonus_report.php?user_id=${user_id}`);
        const result = await response.text(); // Assuming the API returns HTML content
        setIframeContent(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user_id]);
  return (
    <div>
      {isLoading && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}

      {!isLoading && (

        <section id="Help" className='margin-bottom-88'>
          {/* Your iframe code here */}
          <div className='margin-bottom-88 mb-0'>
            {/* <div className="pb-4">
              <iframe src={`https://visa.a2logicgroup.com/kgfmatka/public/api/POM_bonus_report.php?user_id=${user_id}`} style={{ width: '100%', height: '100vh' }} />
            </div> */}
          
              <iframe
                title="API Content"
                srcDoc={iframeContent}  // Set the HTML content here
                style={{ width: '100%', height: '100vh' }}
              />
            
            
          </div>

          {/* Rest of your content */}
        </section>
      )}
    </div>
  )
}
