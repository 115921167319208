import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import { Col, Row, Divider, DatePicker, Select, Space } from 'antd';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

// import datanotfound from '../../assets/img/datanotfound.png'
export default function History() {
  const [users, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [date_time, setDate] = useState('');
  const [market_name, setMarketName] = useState('');
  const [loading1, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState(undefined);
  const [loading2, setLoading2] = useState(false);
  const [dateRanges, setDateRange] = useState([]);
  const [comxMarket, setComxMarket] = useState([]);


  const handleReload = () => {
    // Scroll to the top of the window
    window.scrollTo(0, 0);
    // Reload the page
    window.location.reload();
  };
  const handleDateRangeChange = (dates) => {
    // alert(dates);
    if (dates && dates.$L && dates.$d) {
      const formattedDate = new Intl.DateTimeFormat(dates.$L, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        
      }).format(dates.$d);

      setDateRange(formattedDate);
      // console.warn(formattedDate);
      loaduser(formattedDate, selectedValue);
    } else {
      console.error("Invalid 'dates' object");
    }
  };

  const [selectedValue, setSelectedValue] = useState('');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    loaduser(dateRanges, event.target.value);
  };

  const loaduser = async (formattedDate, tablecode) => {
    console.warn(formattedDate + 'ooooooo');
    if (tablecode == undefined) {
      tablecode = '';
    }

    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    setLoading(true);
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/betHistory.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    // formData.append('market_id', gameid);
    formData.append('dev_id', dev_id);
    // formData.append('type', '2');
    // if(formattedDate != ''){
    formData.append('flt_date', formattedDate);
    // }
    formData.append('tbl_code', tablecode);

    // formData.append('market_name', market_name);

    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);

        // alert(res);
        // console.warn(res+'dfdff');
        if (res != undefined) {
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes);
        } else {
          setUsers();
        }

      })
      .finally(() => {
        setLoading(false);
      });
  }
  const deleteCombination = (id) => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    const url = `${process.env.REACT_APP_API_URL}/POM_betDelete.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('bet_id', id);

    axios.post(url, formData)
      .then(function (response) {
        setLoading2(false);
        if (response.data.success) {
          // Remove the deleted item from the UI here
          // For example, if you have an array of items, you can filter out the deleted item

          // setUsers(users.filter(item => item.id !== id));
          const userConfirmed = window.confirm('Are you sure you want to remove this Market?');

          if (userConfirmed) {
            // Filter out the user with the specified id
            const updatedUsers = users.filter(item => item.id !== id);

            // Update the state with the filtered users
            setUsers(updatedUsers);

            // Show an alert after removing the user
            alert('Market bet removed successfully!');
          } else {
            // Show a cancellation message if the user cancels the operation
            alert('Market bet removal canceled.');

          }

          // toast.success('Market deleted successfully!');
        }
        // else {
        //   toast.error('Failed to delete Market!');
        // }
      })
      .catch(function (error) {
        setLoading2(false);
        console.error('Error deleting market:', error);
        // toast.error('Failed to delete Market!');
      });
  };


  useEffect(() => {
    loaduser();
  }, [])
  useEffect(() => {
    ComxMarket();
  }, [])

  const ComxMarket = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // setLoading(true);

    // alert(user_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_all_market.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setComxMarket(objectRes);
        console.warn(objectRes);
      })
      // .finally(() => {
      //   // setTimeout(() => {
      //   setLoading(false);
      //   // }, 1000);
      // });
  }

  return (
    <>
      <section className="margin-bottom-88" id='history'>
        <div className="container-fluid paddingall-5">
          <div className='historybet marketdetails'>
            <Divider orientation="center">Game History</Divider>

            <Row gutter={16}>
              <Col span={10}>
                {/* <DatePicker onChange={(date, dateString) => setDate(dateString)} /> */}
                <DatePicker onChange={handleDateRangeChange} />
              </Col>
              <Col className="gutter-row" span={10}>
                <Space>
                  <select value={selectedValue} onChange={handleSelectChange} className='select_market'>
                    <option value="">Select an option</option>
                    {comxMarket.map((item, index) => (
                      <option key={index.id} value={item.id}>
                        {item.id}
                      </option>
                    ))}
                  </select>
                </Space>
              </Col>

              <Col span={4}>
                <div className="reloadhistory ms-2" onClick={handleReload}>
                  <i class="bi bi-arrow-clockwise"></i>
                </div>
              </Col>
            </Row>
          </div>
          <div className="table-responsive">

            <div className="table  table-history">
              <Table striped bordered hover className=''>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Number </th>
                    <th>Points </th>
                    <th>Earned</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                {loading1 ? (
                  <div className="spinner-wrapper">
                    <div className="loadernew2"></div>
                  </div>
                ) : (
                  <tbody className='bgbody'>
                    {users && users.length > 0 ? (
                      users.map((user, index) => {
                        const dateTime = new Date(user.date_time);
                        const formattedDate = `${dateTime.getDate()}-${dateTime.getMonth() + 1}-${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}`;
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{formattedDate}</td>
                            <td>{user.market_name}</td>
                            <td>{user.game_type}</td>
                            <td>{user.pred_num}</td>
                            <td>{user.tr_value}</td>
                            <td>{user.win_value}</td>
                            <td></td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" className='text-center nodataavl'>No data available or something went wrong.</td>
                      </tr>
                    )}
                  </tbody>
                )}


              </Table>
            </div>
          </div>
        </div>
      </section >
      <ToastContainer />

      {loading2 && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
    </>
  )
}
