import React from 'react'
import logo from '../../assets/img/logo.png'
import { Link } from 'react-router-dom'
export default function Gameposting() {
  return (
    <>
    <section className="chat" id="chat">
        <div className="headerchat">
            <div className="d-flex justify-content-between align-items-center">
                 <div className="headericonarrow">
                    <Link className='arrowlink' to="/Home">
                     <i class="bi bi-arrow-left-short"></i>
                     </Link>
                 </div>
                 <div className="chatname">
                    <h2>Deposit Chat</h2>
                 </div>
                 <div className="logoheader">
                    <img src={logo} className="img-fluid"/>
                 </div>
            </div>
        </div>
            <div className="container">
                <div className="chatdesignuser1">
                   
                </div>
                <div className="d-flex chatdesign">
                    <div className="inputchat">
                        <input type="text" className='form-control' placeholder='Type Message'/>
                    </div>
                    <div className="buttonsend">
                    <i class="bi bi-send"></i>
                    </div>
                </div>
            </div>
    </section>
    </>
  )
}
