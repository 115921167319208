import * as Icon from "react-bootstrap-icons";

const user_id = localStorage.getItem("userid");

 const SidebarDataOne = [

  {
    title: "Logout",
    // path:"/Login",
    icon: <Icon.BoxArrowLeft />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
];

export default SidebarDataOne;