import React,{useState,useEffect} from 'react'

export default function Termsandcondition() {
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
    
       setIsLoading(false);
 
   
   }, []); 
 
  return (
   <>
   {isLoading && (
        // <>
        // <div className="loader_roomcode">
        //   <img src={loader} alt="loader" />
        // </div>
        // </>
        <div className="spinner-wrapper">
        <div className="loadernew2"></div>
        </div>  
      )}

    {!isLoading && (
      <section id="Help" className='margin-bottom-88'>
        {/* Your iframe code here */}
        <div className='margin-bottom-88 mb-0'>
          <div className="pb-4">
            <iframe src={`${process.env.REACT_APP_API_URL}/pages/help.php`} style={{ width: '100%', height: '100vh' }} />
          </div>
        </div>
        {/* Rest of your content */}
      </section>
    )}
    </>
   
  )
}
