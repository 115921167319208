import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";

export default function ReferCode() {
  const user_id = localStorage.getItem("userid");
  const [link, setLink] = useState('');
  const [refcode, setRefcode] = useState('');

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: `अपने दोस्तों को रेफर करें और अपने दोस्तों की प्रत्येक हानि बोली (बुकिंग) पर 5% कमीशन राशि प्राप्त करें रेफरल कोड  का उपयोग करके| ${refcode}`,
        url: `https://bossonlinematka.com/boss-matka.apk ${refcode}`,
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      const unsupportedBrowserMessage = `
        Sharing is not supported in this browser.
        To share, please manually copy and paste the following link:
        https://bossonlinematka.com/boss-matka.apk
      `;
      // alert(unsupportedBrowserMessage);
    }
  };

  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const ref_code = response.data.refCode;
        setRefcode(ref_code);
        const objectRes = JSON.parse(res);
        refercode(ref_code);
      })
  }

  const [linkwhatsapp, linkwhatsappDta] = useState('');

  function refercode(ref_code) {
    linkwhatsappDta(`//api.whatsapp.com/send?text=अपने दोस्तों को रेफर करें और पाएं उनकी लॉस बुकिंग पर 5% commison। (जिसने लॉस बुकिंग की है उसे भी 2% cashback milega)
 | रेफरल कोड ${ref_code} https://bossonlinematka.com/boss-matka.apk`);
    setLink(`https://bossonlinematka.com/Register?reffercode=${ref_code}`);
  }

  useEffect(() => {
    loaduser1();
  }, [])

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
       console.warn('Link copied to clipboard: ' + copyMe);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      <section className='container refer'>
        <div className='margin-bottom-88 mb-0'>
          <div className="pt-1">
            <h3 className='text-dark text-center mt-3'>Refer & Earn</h3>
            <p className='text-secondary'>अपने दोस्तों को रेफर करें और पाएं उनकी लॉस बुकिंग पर 5% commison। (जिसने लॉस बुकिंग की है उसे भी 2% cashback milega)
            </p>
            {/* <p className='px-2 mt-3'><span className='text-color-danger'>नोट :</span> आपको 5% कमीशन तभी मिलेगा अगर आपका कोई friend गेम खेलता है । और उस गेम से कंपनी को जो Profit हुआ उसका 5% आपको मिलेगा |</p> */}
            <div className='d-flex justify-content-between'>
              <Link to={linkwhatsapp} className='d-block btnbal w-100 btn btn-transparent border border-danger mx-1'>
                <i className="bi bi-share-fill sharebtn color_result"></i>
                Share & Earn
              </Link>
              <Link to='' className='d-block btnbal w-100 btn btn-transparent border border-danger mx-1' onClick={() => copyToClipBoard(link)}>
                <i className="bi bi-copy me-2 sharebtn color_result"></i>
                Copy Link
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
