import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

export default function Help() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 2 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Clear the timer if the component unmounts before 2 seconds
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures the effect runs once after initial render

  return (
    <>
      {isLoading && (
           <div className="spinner-wrapper">
           <div className="loadernew2"></div>
           </div>
      )}

      {!isLoading && (
        <section id="Help" className='margin-bottom-88'>
          {/* Your iframe code here */}
          <div className='margin-bottom-88 mb-0'>
            <div className="pb-4">
              <iframe src={`${process.env.REACT_APP_API_URL}
              
              
              /pages/help.php`} style={{ width: '100%', height: '100vh' }} />
            </div>
          </div>
          {/* Rest of your content */}
        </section>
      )}
    </>
  );
}
