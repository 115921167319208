import React from 'react'
import Table from 'react-bootstrap/Table';
import { Input } from 'antd';

export default function Manual() {

    const onChange = (e) => {
        console.log('Change:', e.target.value);
      };
    return (
        <>
            <section id="manual">
                <div className="d-flex justify-content-between px-3">
                    <div className="points">
                        <h5>Points Remaining</h5>
                        <p>0</p>
                    </div>
                    <div className="points">
                        <h5>Points Added</h5>
                        <p>0</p>
                    </div>
                </div>
                <div className='table-responsive'>
                    <Table striped bordered hover>
                        <thead className='tablehead'>
                            <tr>

                                <th>Jodi</th>
                                <th>Point</th>
                                <th>Total</th>

                            </tr>
                        </thead>
                        <tbody className='padding-table'>
        <tr>
          <td className='d-flex'>
            <tr>
                <td>
                <Input maxLength={2} onChange={onChange} className="width_input" />
             
                </td>
            </tr> 
            <tr>
                <td>
                <Input maxLength={2} onChange={onChange} className="width_input" />
                </td>
            </tr> 
            <tr>
                <td>
                <Input maxLength={2} onChange={onChange} className="width_input" />
                </td>
            </tr> 
            <tr>
                <td>
                <Input maxLength={2} onChange={onChange} className="width_input" />
                </td>
            </tr> 
            <tr>
                <td>
                <Input maxLength={2} onChange={onChange} className="width_input" />
                </td>
            </tr> 
         
          </td>
          <td>
          <tr>
                <td>
                                    <Input maxLength={2} onChange={onChange} className="width_input" />

                </td>
            </tr> 
          </td>
          <td>
          <tr>
                <td>
                                    <Input maxLength={2} onChange={onChange} className="width_input" />

                </td>
            </tr> 
          </td>
        
        </tr>
       
        
                                  
                        </tbody>
                    </Table>
                </div>
            </section>
        </>
    )
}
