import React, { useState, useEffect } from 'react'
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
// import Loader from './Loader'; 

export default function Notification() {
  
  const [users, setUsers] = useState([{}]);
  const [loading1, setLoading] = useState(false);

  useEffect(() => {
    loaduser();
    setTimeout(() => {
      // setLoading(true);
    }, 2000);
  }, [])

  const loaduser = async () => {
    setLoading(true);

    const user_id = localStorage.getItem("userid");
    // alert(user_id);
    try {
    let url = (`${process.env.REACT_APP_API_URL}/POM_notice.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);

      })
      .finally(() => {
        // setTimeout(() => {
        setLoading(false);
      // }, 1000);
      });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }
  
  return (
    <>
    <section id="notification">
        <div className='margin-bottom-88'>
        {users && users.map((user) => (
          <>
           <div className="notficationbox">
             <div className="d-flex justify-content-between align-items-center">
             <h6 className="titlenotification text-danger" > {user.title}</h6>
               {/* <p>Jai Baba ji Ki</p> */}
             </div>
               <p className="notificationdes">
               {ReactHtmlParser(user.description)}</p>
               <div className="d-flex justify-content-between">
               <time className="time text-dark">{user.date}</time>
               {/* <p>Timer:09:15:21</p> */}
               </div>
           </div>
           
           </>
        ))}
        <div>
        {loading1 && (
        // <>
        // <div className="loader_roomcode">
        //   <img src={loader} alt="loader" />
        // </div>
        // </>
        <div className="spinner-wrapper">
        <div className="loadernew2"></div>
        </div>
      )}
     
    </div>
        </div>
        
    </section>
      
    </>
  )
}
