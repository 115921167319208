import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Container } from 'react-bootstrap';

import { ToastContainer } from 'react-toastify';
import axios from "axios"; import logo from '../../assets/img/logo.png'
import { Link } from 'react-router-dom'
export default function Gameposting() {

  const [users, setUsers] = useState([]);

  useEffect(() => {
    loaduser();
  }, [])
  const loaduser = async () => {
    // setLoading(true);
    const user_id = localStorage.getItem("userid");
    try {
      let url = (`${process.env.REACT_APP_API_URL}/getGroupMsg.php`);
      const formData = new FormData();
      formData.append('user_id', user_id);
      var config = {
        method: 'POST',
        url: url,
        body: formData,
      };
      axios.post(url, formData, config)
        .then(function (response) {
          const res = JSON.stringify(response.data.data);
          // console.warn(res)
          const objectRes = JSON.parse(res);
          setUsers(objectRes[0]);
          // console.warn(objectRes[0]);
        })
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  const [message, setMessage] = useState('');

  const OnnumberChange = (e) => {
    const mobilnumbers = e.target.value;
    setMessage(mobilnumbers);
  };

  const addmsggroup = async () => {
    const user_id = localStorage.getItem("userid");
    try {
      const formData = new FormData();
      formData.append('message', message);
      formData.append('user_id', user_id);

      let url = `${process.env.REACT_APP_API_URL}/addMsgGroup.php`;
      const config = {
        method: 'POST',
        url: url,
        data: formData,
      };

      axios(config)
        .then(function (response) {
          const res = JSON.stringify(response.data);
          if (response.data.success == 1) {
            console.warn(response.data.success)
            const MySwal = withReactContent(Swal)
            MySwal.fire({
              title: response.data.message,
              icon: 'success',
              timer: 2000
            })
              // toast.success( response.data.message)
              .then((result) => {
                window.location.reload();
              })
          } else {

            toast.error(response.data.message);

          }
        });
    } catch (error) {
      console.error('User Name Update:', error);
      // toast.error('An error occurred while updating username. Please try again later.');
    }
  };
  return (
    <>
      <section className="chat" id="chat">
        <div className="headerchat">
          <div className="d-flex justify-content-between align-items-center">
            <div className="headericonarrow">
              <Link className='arrowlink' to="/Home">
                <i class="bi bi-arrow-left-short"></i>
              </Link>
            </div>
            <div className="chatname">
              <h2>Game Posting</h2>
            </div>
            <div className="logoheader">
              <img src={logo} className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="chatdesignuser1">
            <div class="chat-message-group writer-user">
              <div class="chat-messages">
                <div class="message"><p className='text-left'>{users.name}</p><p>{users.message}</p><span className='datechat'>{new Date(users.datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span></div>
              </div>
            </div>
            {/* <div class="chat-message-group leftchat">

                            <div class="chat-messages">
                                <div class="message">Olá meu nome é Marinho</div>
                            </div>
                        </div> */}
          </div>
          <div className="d-flex chatdesign">
            <div className="inputchat">
              <input type="text" className='form-control' placeholder='Type Message' onChange={OnnumberChange} value={message} />
            </div>
            <div className="buttonsend">
              <i onClick={addmsggroup} class="bi bi-send"></i>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  )
}
