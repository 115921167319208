



    


import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'; 


function Faq() {
  const [iframeContent, setIframeContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/POM_faq.php`);
        const result = await response.text(); // Assuming the API returns HTML content  
        setIframeContent(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      // setIsLoading(false);
    };

    fetchData();
  }, []);

  return (

    <>   
    <p style={{paddingTop:"60px", margin:"10px"}}>
    <div className='margin-bottom-88 mb-0'>
    <iframe
                title="API Content"
                srcDoc={iframeContent}  // Set the HTML content here
                style={{ width: '100%', height: '100vh' }}
              />
        </div>

    </p>
    </>
  )
}

export default Faq
