import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import loading1 from '../../assets/img/loading-gif.gif';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { UserOutlined } from '@ant-design/icons';
// import { PhoneOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import Tab from 'react-bootstrap/Tab';
import Logo from '../../assets/img/logo.png';
import $ from 'jquery';
import { fetchwalletamount } from '../../common.js';

import { Container } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'
import { Button, Form, Input, message, Space, Modal } from 'antd';
import Withdrawhistory from './Withdrawhistory';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from "react-bootstrap";

import { toast } from 'react-toastify';
export default function Wallet() {
  const [walletAmount, setWalletAmount] = useState(null);

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const [selectedOption, setSelectedOption] = useState(true);
  const [amountvalue, setvalue] = useState("");
  const [msg, setmsg] = useState("");
  const [point, setPoint] = useState("");
  // const [accountno, setAccountno] = useState("");
  // const [accountnoconf, setAccountnoconf] = useState("");
  // // const [ifcecode, setIfcecode] = useState("");
  // const [ifcecodeconf, setIfcecodeconf] = useState("");
  const [msgwallet, setmsgwallet] = useState("");
  const [users, setUsers] = useState([]);
  const [user, setUsers1] = useState([]);
  const navigate = useNavigate();
  const [loadingbutton, setLoadingbutton] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [userData, setUserData] = useState(null);
  const [appmanager, setAppmanager] = useState(null);
  const [minredeem, setMinredeem] = useState(null);
  const [Deposit, setDeposit] = useState(null);
  console.warn(Deposit)
  const [isLoading, setIsLoading] = useState(true);
  const [winamount, setwinamount] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loadingwithdraw, setLoadingwithdraw] = useState(false);
  const [loadingwallet, setLoadingwallet] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);





  const [bankNumber, setBankNumber] = useState('');

  console.warn(bankNumber)
  // console.warn(bankNumber)
  const [ifscCodee, setIfscCodee] = useState('');
  const [inputValueee, setinputValue] = useState('');

  const handleBankNumberChange = (e) => {
    setBankNumber(e.target.value);
  };
  const inputValueNumberChange = (e) => {
    setinputValue(e.target.value);
    if (e.target.value) {
      setInputError(false);
    }
  };

  const handleIfscCodeChange = (e) => {
    setIfscCodee(e.target.value);
  };
















  const changevalue = (e) => {
    setvalue(e.target.value)
  }
  const handleButtonClickdeposit = (amount) => {
    // setSelectedAmount(amount);
    setInputValuedeposit(amount);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const [form] = Form.useForm();

  const onFinish = () => {
    // message.success('Submit success!');
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  useEffect(() => {
    loaduser();
    loaduser1();
    getuser();
    app_manager();
  }, [])



  const getuser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const tr_value1 = response.data.tr_value;
        setPoint(tr_value1)
        const acc_no = response.data.account_no;
        const acc_noconf = response.data.account_no;
        // setAccountno(acc_no)
        // setAccountnoconf(acc_noconf)
        console.warn(acc_no)
        const ifce_code = response.data.ifsc_code;
        const ifce_codeconf = response.data.ifsc_code;
        console.warn(ifce_code)
        // setIfcecode(ifce_code)
        // setIfcecodeconf(ifce_codeconf)
        const objectRes = JSON.parse(res);
        setUserData(objectRes);

      })
  }
  // const app_manager = async () => {
  //   const user_id = localStorage.getItem("userid");
  //   const dev_id = localStorage.getItem("dev_id");

  //   let url = `${process.env.REACT_APP_API_URL}/POM_app_manager.php`;
  //   const formData = new FormData();
  //   formData.append('app_id', process.env.REACT_APP_API_ID);
  //   formData.append('user_id', user_id);
  //   formData.append('dev_id', dev_id);

  //   var config = {
  //     method: 'POST',
  //     url: url,
  //     data: formData, // Use 'data' instead of 'body' for Axios
  //   };

  //   try {
  //     const response = await axios(config);
  //     const responseData = response.data; // Access the data property of the response
  //     console.warn(responseData)
  //     if (responseData) {
  //       const { min_redeem, min_deposit } = responseData;
  //       setMinredeem(min_redeem);
  //       setDeposit(min_deposit);

  //       const res = JSON.stringify(responseData);
  //       const objectRes = JSON.parse(res);
  //       setAppmanager(objectRes);
  //       setIsLoading(false);
  //     } else {
  //       console.error("API Error: Response data is undefined");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }
  const app_manager = async () => {

    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/POM_app_controller.php`;
    var config = {
      method: 'POST',
      url: url,
    };

    try {
      const response = await axios.post(url, config);
      const responseData = response.data;
      console.warn(responseData)
      // if (responseData) {
      const { min_redeem, min_deposit } = responseData;
      console.warn(responseData.min_deposit)
      // alert('lll')
      setMinredeem(min_redeem);
      setDeposit(min_deposit);

      const res = responseData;
      // console.warn(res.data)
      const objectRes = JSON.parse(res);
      setAppmanager(objectRes);
      setIsLoading(false);
      // } else {
      //   console.error("API Error: Response data is undefined");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    setLoadingwallet(true);

    let url = (`${process.env.REACT_APP_API_URL}/POM_wallet_history.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    // formData.append('type', '1'); 
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        // console.warn(res)
        const msgwallet = response.data.message;
        setmsgwallet(msgwallet)
        var winamount = response.data.totalAmount;
        // alert(winamount)
        // console.warn(winamount);
        setwinamount(winamount);
        if (res != undefined) {
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          // console.warn(objectRes);
        }
        // const objectRes = JSON.parse(res);
        // setUsers(objectRes);
        // setIsLoading(false);
        // console.warn(objectRes);

      })
      .finally(() => {
        setLoadingwallet(false);
      });
  }

  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    setLoadingwithdraw(true);
    let url = (`${process.env.REACT_APP_API_URL}/POM_withdraw_history.php`);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    // formData.append('type', '3');
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const datetime = response.data;
        // console.warn(tr_value_updated)
        const msg = response.data.message;

        setmsg(msg)
        if (res != undefined) {
          const objectRes = JSON.parse(res);
          setUsers1(objectRes);
          // console.warn(objectRes);
        }
        // const objectRes = JSON.parse(res);
        // setUsers1(objectRes);
        // console.warn(objectRes);

      })
      .finally(() => {
        setLoadingwithdraw(false);
      });
  }

  const user_id = localStorage.getItem("userid");
  const dev_id = localStorage.getItem("dev_id");
  const [inputValuedeposit, setInputValuedeposit] = useState('');



  const handleDeposit = () => {
    if (parseInt(Deposit) > parseInt(inputValuedeposit)) {
      // alert('5')
      console.warn(inputValuedeposit);
      toast.error(`Minimum Redeem Amount ${Deposit}`);
      return;
    }
    if (inputValuedeposit) {
      const apiUrl = `https://bossonlinematka.com/Admin/public/payment-getway?name=${userData.name}&userid=${user_id}&amount=${inputValuedeposit}&contact=${userData.mob}&getaway=razorpay`;
      window.location.href = apiUrl;
    } else {

    }
  };

  // Withdrawal Api

  const [bankName, setBankName] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [inputError, setInputError] = useState(false);
  const [bankNameError, setBankNameError] = useState(false);
  const [accountHolderNameError, setAccountHolderNameError] = useState(false);
  const [accountNumberError, setAccountNumberError] = useState(false);
  const [ifscCodeError, setIfscCodeError] = useState(false);
  const [mobilnumbererror, setMobileNumberError] = useState(false);
  const [amounttrerror, setAmountError] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [message, setmessage] = useState("");

  const [accountNumberConfirmation, setAccountNumberConfirmation] = useState('');
  const [ifscCodeConfirmation, setIfscCodeConfirmation] = useState('');
  //  const OnDobInputChange = (e) => {
  //     const newDate = e.target.value;
  //     dateOfBtUpdate(newDate);
  //   };
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // const handleWithdrawal = async () => {

  //   try {
  //     setLoadingbutton(true);
  //     // setShowSubmitButton(false);
  //     const errors = [];
  //     setInputError(false);
  //     setButtonDisabled(true); // Disable button

  //     setBankNameError(false);
  //     setAccountHolderNameError(false);
  //     setAccountNumberError(false);
  //     setIfscCodeError(false);

  //     // if (!accountNumber) {
  //     //   setAccountNumberError(true);
  //     //   errors.push('Please enter a valid Account Number');
  //     // }
  //     // if (!ifcecode) {
  //     //   setIfscCodeError(true);
  //     //   errors.push('Please enter a valid Ifce Code');
  //     // }

  //     if (!inputValueee || isNaN(inputValueee) || inputValueee <= 0) {
  //       setInputError(true);
  //       errors.push('Please enter a valid amount');
  //     }



  //     if (accountNumber !== accountNumberConfirmation) {
  //       // Show an error message or handle the mismatch appropriately
  //       toast.error("Account number and confirmation do not match");
  //       return;
  //     }

  //     // Check if the entered IFSC code matches the confirmation
  //     if (ifscCode !== ifscCodeConfirmation) {
  //       // Show an error message or handle the mismatch appropriately
  //       toast.error("IFSC code and confirmation do not match");
  //       return;
  //     }
  //     // if (!accountNumber || !accountNumberConfirmation || accountNumber !== accountNumberConfirmation) {
  //     //   toast.error("Please enter and confirm account number correctly");
  //     //   return;
  //     // }

  //     // if (!ifscCode || !ifscCodeConfirmation || ifscCode !== ifscCodeConfirmation) {
  //     //   toast.error("Please enter and confirm IFSC code correctly");
  //     //   return;
  //     // }


  //     if (errors.length) {
  //       errors.push(errors.join('\n'));
  //       return;
  //     }
  //     const user_id = localStorage.getItem("userid");
  //     const dev_id = localStorage.getItem("dev_id");
  //     const formData = new FormData();
  //     formData.append('app_id', process.env.REACT_APP_API_ID);
  //     formData.append('dev_id', dev_id);
  //     formData.append('user_id', user_id);
  //     formData.append("point", inputValueee);
  //     formData.append("bank_name", bankName);
  //     formData.append("account_holder_name", accountHolderName);
  //     formData.append("account_number", bankNumber);
  //     formData.append("ifsc_code", ifscCodee);




  //     setLoading3(false);
  //     let url = `${process.env.REACT_APP_API_URL}/POM_deduct_withdrawal.php`;
  //     const config = {
  //       method: 'POST',
  //       url: url,
  //       data: formData,
  //     };

  //     axios(config)
  //       .then(function (response) {
  //         const res = JSON.stringify(response.data);
  //         setmessage(response.data.message);
  //         if (response.data.success == "1") {
  //           getuser();
  //           // loaduser1();
  //           setLoading3(false);


  //           // console.warn(response.data.success)
  //           const MySwal = withReactContent(Swal)
  //           MySwal.fire({
  //             title: response.data.message,
  //             icon: 'success',
  //             timer: 2000
  //           })
  //             .then((result) => {
  //               sessionStorage.setItem("acc_no", response.data.account_no);
  //               sessionStorage.setItem("ifce_code", response.data.ifsc_code);
  //               window.location.href = '/';
  //               // setShowSubmitButton(false);
  //             })

  //         } else {
  //           const MySwal = withReactContent(Swal)
  //           MySwal.fire({
  //             title: response.data.message,
  //             timer: 2000,
  //             icon: 'error'
  //           })
  //             .then((result) => {
  //               // window.location.href = '/Wallet';
  //               // navigate(0)
  //               // setShowSubmitButton(false);
  //             })
  //         }
  //       });


  //   }
  //   catch (error) {
  //     console.error('Error handling withdrawal:', error);
  //   } finally {
  //     setLoadingbutton(false);
  //     setButtonDisabled(false); // Re-enable button after processing

  //     // setLoading3(false);
  //     setShowSubmitButton(false);
  //   }


  // };

  
  
  const handleWithdrawal = async () => {
    try {
      setLoadingButton(true);
      setButtonDisabled(true); // Disable button

      const errors = [];
      setInputError(false);
      setBankNameError(false);
      setAccountHolderNameError(false);
      setAccountNumberError(false);
      setIfscCodeError(false);

      // Validate input fields
      if (!inputValueee || isNaN(inputValueee) || inputValueee <= 0) {
        setInputError(true);
        errors.push('Please enter a valid amount');
      }
      if (accountNumber !== accountNumberConfirmation) {
        toast.error("Account number and confirmation do not match");
        return;
      }
      if (ifscCode !== ifscCodeConfirmation) {
        toast.error("IFSC code and confirmation do not match");
        return;
      }

      if (errors.length) {
        toast.error(errors.join('\n'));
        return;
      }

      const user_id = localStorage.getItem("userid");
      const dev_id = localStorage.getItem("dev_id");
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('dev_id', dev_id);
      formData.append('user_id', user_id);
      formData.append("point", inputValueee);
      formData.append("bank_name", bankName);
      formData.append("account_holder_name", accountHolderName);
      formData.append("account_number", bankNumber);
      formData.append("ifsc_code", ifscCodee);

      setLoading3(false);
      const url = `${process.env.REACT_APP_API_URL}/POM_deduct_withdrawal.php`;
      const config = {
        method: 'POST',
        url: url,
        data: formData,
      };

      const response = await axios(config);
      const res = response.data;

      setmessage(response.data.message);
  const MySwal = withReactContent(Swal);

      if (res.success === "1") {
        getuser();
        setLoading3(false);

        MySwal.fire({
          title: res.message,
          icon: 'success',
          timer: 2000
        }).then(() => {
          sessionStorage.setItem("acc_no", res.account_no);
          sessionStorage.setItem("ifce_code", res.ifsc_code);
          window.location.href = '/';
        });
      } else {
        MySwal.fire({
          title: res.message,
          icon: 'error',
          timer: 2000
        });
      }
    } catch (error) {
      console.error('Error handling withdrawal:', error);
    } finally {
      setLoadingButton(false);
      setButtonDisabled(false); // Re-enable button after processing
      setShowSubmitButton(false); // Assuming this is part of your UI state management
    }
  };
  const handleButtonClick = (amount) => {

    setInputValue(amount);
  };



  // ------transferpoints Api ------- //


  const [mobilnumber, setmobilNumber] = useState('');
  const [amounttr, setAmounttr] = useState('');

  const OnnumberChange = (e) => {
    const mobilnumbers = e.target.value;
    setmobilNumber(mobilnumbers);
  };

  const OnamountChange = (e) => {
    const amount = e.target.value;
    setAmounttr(amount);
  };

  const transferpoints = async () => {

    const errors = [];

    const mobilNumberRegex = /^\d+$/;
    const amountRegex = /^\d+$/;

    if (!mobilNumberRegex.test(mobilnumber)) {
      setMobileNumberError(true);
      errors.push('Please enter a valid number');
    }

    if (!amountRegex.test(amounttr)) {
      setAmountError(true);
      errors.push('Please enter a valid Amount');
    }


    try {
      const user_id = localStorage.getItem("userid");
      const dev_id = localStorage.getItem("dev_id");
      const formData = new FormData();
      formData.append('rec_mob', mobilnumber);
      formData.append('amount', amounttr);
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      formData.append('devName', dev_id);
      // formData.append('devName', 'web');
      setLoading2(false);
      let url = `${process.env.REACT_APP_API_URL}/POM_transfer.php`;
      const config = {
        method: 'POST',
        url: url,
        data: formData,
      };

      axios.post(url, formData, config)
        .then(function (response) {
          const res = JSON.stringify(response.data);
          if (response.data.status == 1) {
            // console.warn('ddddddd');
            getuser();
            loaduser();
            // setLoading2(false);
            // setShowSubmitButton(false);
            setOpen(false);

            toast.success(response.data.message, {
              onClose: () => {
                // Reload the window after the toast message is closed
                // window.location.reload();
              }
            });
            // const MySwal = withReactContent(Swal)
            // MySwal.fire({
            //   title: response.data.message,
            //   icon: 'success',
            //   timer: 2500
            // })
          } else {
            toast.error(response.data.message);
          }
        });
    } catch (error) {
      console.error('User Name Update:', error);
      // toast.error('An error occurred while updating username. Please try again later.');
    }
    // setLoading2(true);
  };



  const [credit, setCredit] = useState('');

  useEffect(() => {
    // Retrieve the credit value from localStorage
    const storedCredit = localStorage.getItem('credit');
    if (storedCredit) {
      setCredit(storedCredit);
    }
  }, []);
  return (
    <>
      <Space>

        <Button
          type="primary"
          onClick={() => {
            Modal.confirm({
              title: 'Confirm',
              content: 'Bla bla ...',
              footer: (_, { Submit }) => (
                <>
                  <Submit />
                </>
              ),
            });
          }}
        >
          Open Modal Confirm
        </Button>
      </Space>
      {/* {showSubmitButton && */}
      <Modal
        open={open}
        title="Transfer"
        onOk={handleOk}
        className='model-transferpoint'
        onCancel={handleCancel}
        footer={(_, { }) => (
          <>
            {showSubmitButton && <Button className='submitform' onClick={transferpoints}>Submit</Button>}
          </>
        )}
      >
        <div className="logo d-flex justify-content-center w-100 logomodel">
          <img src={Logo} />
        </div>
        <p className='pointsid text-white p-2 text-center'>यहां से आप अपने POINT अपने दोस्तो की ID मैं डाल सकते हो</p>
        <Input placeholder="Enter Mobile Number" value={mobilnumber} onChange={OnnumberChange} type='number' maxLength={6} className={`form-control ${mobilnumbererror ? 'is-invalid' : ''} mb-2`} />
        {mobilnumbererror && (
          <div className="invalid-feedback">Please enter a valid Number</div>
        )}
        <Input placeholder="Amount" type='number' value={amounttr} onChange={OnamountChange} className={`form-control ${amounttrerror ? 'is-invalid' : ''}`} />
        {amounttrerror && (
          <div className="invalid-feedback">Please enter a valid Amount</div>
        )}
      </Modal>
      <section id="wallet" className="margin-bottom-88">
        <Container fluid className="p-0 margin-bottom-70">
          <div className="homecontainer">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="w-100">
              <div className="">
                <div className='tabs_wallet'>
                  <Nav variant="pills" className="tabslinks">
                    <Nav.Item>
                      <Nav.Link eventKey="first" className='text-center AddPoint'>Add Point</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second" className='text-center Withdraw'>Withdraw</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

              </div>
              <div >
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className='tab_content_one position-relative'>
                      <div className='addfundwallet'>
                        <div className="bankicon">
                          <Icon.Bank />
                        </div>
                        <input
                          type="number"
                          value={inputValuedeposit}
                          onChange={(e) => {
                            // Ensure the input value is a number
                            const inputValue = parseInt(e.target.value, 10);

                            // Check if the input is a valid number and limit it to 5 digits
                            if (!isNaN(inputValue) && inputValue >= 0) {
                              setInputValuedeposit(inputValue.toString().slice(0, 4));
                            } else {
                              setInputValuedeposit('');
                            }
                          }}
                          required
                          className='form-control addamountinput'
                          placeholder='Add Amount'
                        />

                      </div>
                      <div className='button-amount d-flex flex-wrap'>
                        {/* <button className='btn-amount' onClick={() => handleButtonClickdeposit("500")}>₹ 500</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("1000")}>₹ 1000</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("1500")}>₹ 1500</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("2000")}>₹ 2000</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("2500")}>₹ 2500</button>
                        <button className='btn-amount' onClick={() => handleButtonClickdeposit("3000")}>₹ 3000</button> */}
                      </div>
                      <p className="description mt-2 text-danger">{appmanager && appmanager.dep_message}</p>
                      <center>


                        {/* <h5>Win Amount :<strong>0</strong></h5> */}
                        <div className="d-flex justify-content-between my-2">
                          <button className='refer-button btnaddpoints' onClick={handleDeposit}>
                            Add Points
                          </button>
                          {/* <button className='transferpoints' onClick={showModal}>
                            Transfer Points
                          </button> */}
                        </div>
                        <div className='d-flex justify-content-center radiobtn'>
                        </div>
                      </center>
                    </div>
                    <div className='table-responsive'>
                      <p className='text-center mt-2 requesthistory'>Wallet History</p>

                      <Table striped bordered hover className='tablehistory'>
                        <thead>
                          <tr>
                            <th>Sr No</th>
                            <th>Pay Mode</th>
                            <th>Date</th>
                            <th>Points </th>
                            <th>Closing Balance </th>
                            <th>Status </th>
                          </tr>
                        </thead>
                        <tbody className='position-relative'>
                          {users.length > 0 ? (
                            users && users.map((value, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{value.value_update_by}</td>
                                <td>{value.date_time} </td>
                                <td>{value.tr_value}</td>
                                <td>{value.tr_value_updated}</td>
                                <td className='text-success'> <span style={{ color: value.tr_status === 'Success' ? 'green' : 'red' }}>{value.tr_status}</span></td>

                              </tr>

                            ))
                          ) : (
                            <tr>
                              <td colSpan="10" className='text-center nodataavl'> {msgwallet}</td>
                            </tr>

                          )}
                          {
                            loadingwallet && (
                              // <div className="spinner-wrapper">
                              <div className="loadernew">
                                <img src={loading1} className="px-2 loaderfile" style={{ width: '50px' }} alt="Loading..." />
                              </div>
                              // </div>
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className='tab_content_one position-relative'>
                      <div className='addfundwallet'>
                        <div className="bankicon">
                          <Icon.Bank />
                        </div>

                        {/* <input
                          type="number"
                          value={inputValue || point}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setInputValue(newValue);
                            if (newValue !== point) {
                              setPoint('');
                            }
                          }}
                          className={`form-control ${inputError ? 'is-invalid' : ''} addamountinput`}
                          placeholder='Add Amount'
                        /> */}

                        <input
                          type="number"
                          value={inputValueee}

                          placeholder='Add Amount'
                          onChange={inputValueNumberChange}
                          className={`form-control ${inputError ? 'is-invalid' : ''} addamountinput`}
                        // placeholder='Add Amount'
                        />



                        {/* {inputError && (
                          <div className="invalid-feedback">Please enter a valid amount (e.g., 500 or 500.00)</div>
                        )} */}
                      </div>
                      {/* <div className='button-amount d-flex flex-wrap'>
                        <button className='btn-amount' onClick={() => handleButtonClick("500")}>₹ 500</button>
                        <button className='btn-amount' onClick={() => handleButtonClick("1000")}>₹ 1000</button>
                        <button className='btn-amount' onClick={() => handleButtonClick("1500")}>₹ 1500</button>
                        <button className='btn-amount' onClick={() => handleButtonClick("2000")}>₹ 2000</button>
                        <button className='btn-amount' onClick={() => handleButtonClick("2500")}>₹ 2500</button>
                        <button className='btn-amount' onClick={() => handleButtonClick("3000")}>₹ 3000</button>
                      </div> */}
                      <center>
                        {/* <p className="description mt-2 text-danger text-center">{appmanager && appmanager.dep_message}</p> */}
                        <p className="description mt-2 text-danger text-center">विथड्रावल  डालने  के  3   घंटे  के  अंदर  आपके  अकाउंट  में  पेमेंट  आ  जाएगी </p>
                        <p className='text-center winamount marketnamelist'><h3 className='animationtittle markettitlenam'>User Amount :- </h3> {credit}</p>

                        {/* <h5>Win Amount :<strong>0</strong></h5> */}
                        <p className="text-  text-center">Bank Account Details</p>
                        <div className='d-flex justify-content-center radiobtn'>
                        </div>
                        <Form
                          form={form}
                          layout="vertical"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="on"
                          className=""
                        >

                          {/* <Form.Item

                            className="mb-2"
                            xl={24}
                            rules={[
                              { required: true, message: 'Account Number is required' },
                              { type: 'string', message: 'Please enter a valid Account Number' }
                            ]}
                             >
                            <Input
                              type="number"
                              placeholder="Account Number"
                              value={accountNumber || accountno}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setAccountNumber(newValue);

                                // Check if a new value is entered and clear accountno
                                if (newValue !== accountno) {
                                  setAccountno('');
                                }
                              }}
                              className={`form-control ${accountNumberError ? 'is-invalid' : ''}`}
                            />

                            {accountNumberError && (
                              <div className="invalid-feedback">Please enter a valid account number (numbers only)</div>
                            )}
                          </Form.Item> */}
                          {/* <Form.Item
                            className="mb-2"
                            xl={24}
                            rules={[{ required: true }, { type: 'uri', warningOnly: true }, { type: 'string' }]}
                          >
                            <Input
                              type="number"
                              placeholder="Confirm Account Number"
                              autoComplete="on"
                              value={accountNumberConfirmation || accountnoconf}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setAccountNumberConfirmation(newValue);

                                if (newValue !== accountnoconf) {
                                  setAccountnoconf('');
                                }
                              }}
                            />

                           
                          </Form.Item> */}
                          {/* <Form.Item
                            className="mb-2"
                            xl={24}
                            rules={[
                              { required: true, message: 'IFSC code is required' }, 
                              { type: 'string', message: 'Please enter a valid IFSC code' } 
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="IFSC Code"
                              className='text-transform-uppercase'
                              autoComplete="on"
                              maxLength={11}
                              value={ifscCode || ifcecode}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setIfscCode(newValue);

                                if (newValue !== ifcecode) {
                                  setIfcecode('');
                                }
                              }}
        
                            />

                            {ifscCodeError && (
                              <div className="invalid-feedback">Please enter a valid IFSC code (e.g., ABCD1234567 And 11 Digit)</div>
                            )}
                          </Form.Item> */}

                          {/* <Form.Item
                            className="mb-2"
                            xl={24}
                            rules={[{ required: true }, { type: 'uri', warningOnly: true }, { type: 'string' }]}
                          >
                            <Input
                              type="text"
                              maxLength={11}
                              autoComplete="on"
                              className='text-transform-uppercase'
                              placeholder="Confirm IFSC Code"
                              value={ifscCodeConfirmation || ifcecodeconf}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setIfscCodeConfirmation(newValue);

                                // Check if a new value is entered and clear ifcecodeconf
                                if (newValue !== ifcecodeconf) {
                                  // Perform the action to clear ifcecodeconf
                                  setIfcecodeconf(null); // Assuming ifcecodeconf is a state variable
                                }
                              }}
                            />



                         
                          </Form.Item> */}


                          <Form.Item
                            className="mb-2"
                            rules={[
                              { required: true, message: 'Bank number is required' },
                              { max: 11, message: 'Bank number cannot be longer than 11 characters' },
                            ]}
                          >
                            <Input
                              type="number"
                              maxLength={20}
                              pattern="[0-9]*"

                              autoComplete="on"
                              value={bankNumber}
                              onChange={handleBankNumberChange}
                              className="text-transform-uppercase"
                              placeholder=" Bank Account number"
                            />
                          </Form.Item>




                          <Form.Item
                            className="mb-2"
                            rules={[
                              { required: true, message: 'IFSC Code is required' },
                              { max: 11, message: 'IFSC Code cannot be longer than 11 characters' },
                            ]}
                          >
                            <Input
                              type="text"
                              maxLength={11}
                              autoComplete="on"
                              value={ifscCodee}
                              onChange={handleIfscCodeChange}
                              onKeyDown={(e) => {
                                if (e.key === ' ') {
                                  e.preventDefault();
                                }
                              }}
                              className="text-transform-uppercase"
                              placeholder="IFSC Code"
                            />
                          </Form.Item>







                          <Form.Item>
                            <Space>
                              {/* {<Button type="" htmlType="submit" className='btn_color_all text-white refer-button' disabled={buttonDisabled} // Disable button based on state
                                onClick={handleWithdrawal}>
                                Withdrawal

                              </Button>} */}
                               <Button
      type="submit"
      className='btn_color_all text-white refer-button'
      disabled={buttonDisabled} // Disable button based on state
      onClick={handleWithdrawal}
    >
      Withdrawal
    </Button>


                            </Space>
                            <p className='text-center text-danger mt-2'>{message}</p>
                          </Form.Item>
                        </Form>


                      </center>
                    </div>
                    <div className='table-responsive'>
                      <p className='text-center mt-2 requesthistory'>Withdraw History</p>
                      <Table striped bordered hover className='tablehistory'>
                        <thead>
                          <tr>
                            <th>S No</th>
                            {/* <th>Pay Mode</th> */}
                            <th>Date</th>
                            <th>Points </th>
                            <th>Closing Balance </th>
                            <th>Status </th>
                          </tr>
                        </thead>
                        <tbody className='tbodyhistroy'>
                          {user.length > 0 ? (
                            user && user.map((value, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                {/* <td>{value.value_update_by}</td> */}
                                <td>{value.date_time} </td>
                                <td>{value.tr_value}</td>
                                <td>{value.tr_value_updated}</td>
                                <td className='text-success'> <span style={{ color: value.tr_status === 'Success' ? 'green' : 'red' }}>{value.tr_status}</span></td>
                              </tr>

                            ))
                          ) : (
                            <tr>
                              <td colSpan="10" className='text-center nodataavl'> {msg}</td>
                            </tr>
                          )}

                          {
                            loadingwithdraw && (
                              <div className="spinner-wrapper">
                                <div className="loadernew">
                                  <img src={loading1} className="px-2 loaderfile" style={{ width: '50px' }} alt="Loading..." />
                                </div>
                              </div>
                            )
                          }
                        </tbody>
                        {/* <div className="loadernew">
                              <img src={loading1} className="px-2 loaderfile"  alt="Loading..." />
                            </div> */}

                      </Table>
                    </div>
                  </Tab.Pane>

                </Tab.Content>
              </div>

            </Tab.Container>
          </div>
        </Container >
        <ToastContainer />

      </section >
      {loading2 && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )
      }
      {
        loading3 && (
          <div className="spinner-wrapper">
            <div className="loadernew2"></div>
          </div>
        )
      }


    </>
  )
}
